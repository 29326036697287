<script>
export default {
  name: 'PageLogin',
  data: () => ({
    user: {
      email: '',
      password: '',
    },
  }),
  methods: {
    login() {
      const loader = this.$buefy.loading.open();
      this.$store.dispatch('auth/login', this.user)
        .then(() => {
          const name = this.$store.getters['auth/user'].role ? 'home' : 'admin_index';
          this.$router.push({ name });
        })
        .finally(() => loader.close());
    },
  },
};
</script>

<template>
  <section class="section">
    <form class="box" @submit.prevent="login">
      <b-field label="Email" label-for="email">
        <b-input v-model="user.email" type="email" id="email" required />
      </b-field>
      <b-field label="Mot de passe" label-for="password">
        <b-input v-model="user.password" type="password" id="password" password-reveal required />
      </b-field>
      <b-field>
        <b-button type="is-primary" native-type="submit" expanded>
          Connexion
        </b-button>
      </b-field>
    </form>
  </section>
</template>
